



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Raleway-SemiBold;
  src: url('../fonts/raleway/Raleway-SemiBold.ttf'); 
}

@font-face {
  font-family: Raleway-Bold;
  src: url('../fonts/raleway/Raleway-Bold.ttf'); 
}

@font-face {
  font-family: Raleway-Black;
  src: url('../fonts/raleway/Raleway-Black.ttf'); 
}


/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
	margin: 0px; 
	padding: 0px; 
	box-sizing: border-box;
}

body, html {
	height: 100%;
	font-family: Raleway-SemiBold, sans-serif;
}

/*---------------------------------------------*/
a {
	font-family: Raleway-SemiBold;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-family: Raleway-SemiBold;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #adadad;}
input:-moz-placeholder { color: #adadad;}
input::-moz-placeholder { color: #adadad;}
input:-ms-input-placeholder { color: #adadad;}

textarea::-webkit-input-placeholder { color: #adadad;}
textarea:-moz-placeholder { color: #adadad;}
textarea::-moz-placeholder { color: #adadad;}
textarea:-ms-input-placeholder { color: #adadad;}

label {
  display: block;
  margin: 0;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ utility ]*/




/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.container-contact100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  /* background: #d867c6;
  background: -webkit-linear-gradient(45deg, #c77ff2, #e8519e);
  background: -o-linear-gradient(45deg, #c77ff2, #e8519e);
  background: -moz-linear-gradient(45deg, #c77ff2, #e8519e);
  background: linear-gradient(45deg, #c77ff2, #e8519e); */
  /*background: #7F00FF;  /* fallback for old browsers * /
  background: -webkit-linear-gradient(to right, #E100FF, #7F00FF);  /* Chrome 10-25, Safari 5.1-6 * /
  background: linear-gradient(to right, #E100FF, #7F00FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
/*  background: #000000; */
  background: transparent;
  /*background-image: url(../images/city-fence-1920x2880.jpg);*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;



  
}

.wrap-contact100 {
  width: 790px;
  background: #fff;
  background: #100e0ed9;
  border-radius: 10px;
  padding: 55px 95px 68px 95px;
  box-shadow: 5px 5px 10px black;

}


/*==================================================================
[ Form ]*/

.contact100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact100-form-title {
  width: 100%;
  display: block;
  font-family: Raleway-Black;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 45px;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border: 1px solid #c5bfbf;
  border-radius: 5px;
  margin-bottom: 34px
  
}

.rs1.wrap-input100 {
  /*width: calc((100% - 40px) / 2);*/
  width: 100%;
  margin:auto;
}


.label-input100 {
  font-family: Raleway-SemiBold;
  font-size: 18px;
  color: #555555;
  color: #8c8888;
  line-height: 1.5;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 11px;
  text-align: center;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  background-color: white;
  font-family: Raleway-SemiBold;
  font-size: 18px;
  color: #333333;
  line-height: 1.2;
  padding: 0 15px;
}

.p-input100{
  font-size: 25px;
  font-family: Raleway-SemiBold;
  color: #555;
  line-height: 1.5;
  width: 100%;
  padding-bottom: 11px;
}

.hr-input100{
  border-top: 2px solid #ccc;
  font-family: Raleway-SemiBold;
  color: #555;
  line-height: 1.5;
  width: 100%;
  padding-bottom: 11px;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

input.input100 {
  height: 55px;
}

textarea.input100 {
  min-height: 131px;
  padding-top: 19px;
  padding-bottom: 15px;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid;
  border-radius: 5px;
  border-color: #d867c6;
  border-color: -webkit-linear-gradient(45deg, #c77ff2, #e8519e);
  border-color: -o-linear-gradient(45deg, #c77ff2, #e8519e);
  border-color: -moz-linear-gradient(45deg, #c77ff2, #e8519e);
  border-color: linear-gradient(45deg, #c77ff2, #e8519e);

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}



/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -4px;
  border-width: 2px;
  border-color: white;
}

.contact100-form-btn {
  font-family: Raleway-Bold;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 150px;
  height: 55px;
  border-radius: 27px;
  background: #d867c6;
  background: -webkit-linear-gradient(45deg, #c77ff2, #e8519e);
  background: -o-linear-gradient(45deg, #c77ff2, #e8519e);
  background: -moz-linear-gradient(45deg, #c77ff2, #e8519e);
  background: linear-gradient(45deg, #c77ff2, #e8519e);
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border-width: 2px;
  border-color: white;
}

.contact100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  border-radius: 27px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 1;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border-width: 2px;
  border-color: white;
}

.contact100-form-btn:hover:before {
  opacity: 0;
}

.contact100-form-btn i {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border-width: 2px;
  border-color: white;
}
.contact100-form-btn:hover i {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}


/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 768px) {
  .wrap-contact100 {
    padding: 55px 45px 68px 45px;
  }
  .input100 {
    display: block;
    width: 100%;
    background: white;
    font-family: Raleway-SemiBold;
    font-size: 18px;
    color: #333333;
    line-height: 1.2;
    padding: 0 15px;
  }
  
  .p-input100{
    font-size: 22px;
    font-family: Raleway-SemiBold;
    color: #555;
    line-height: 1.5;
    width: 100%;
    padding-bottom: 11px;
  }
}

@media (max-width: 576px) {
  .wrap-contact100 {
    padding: 55px 15px 68px 15px;
  }

  .rs1.wrap-input100 {
    width: 100%;
  }
  .input100 {
    display: block;
    width: 100%;
    background: white;
    font-family: Raleway-SemiBold;
    font-size: 16px;
    color: #333333;
    line-height: 1.2;
    padding: 0 15px;
  }
  
  .p-input100{
    font-size: 20px;
    font-family: Raleway-SemiBold;
    color: #555;
    line-height: 1.5;
    width: 100%;
    padding-bottom: 11px;
  }

}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate .focus-input100 {
  box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
  -moz-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
  -webkit-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
  -o-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
  -ms-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
  border-radius: 5px;
}

.alert-validate::before {
  content: "";
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background-color: transparent;
  border: 1px solid #fa4251;
  border-radius: 5px;
  top: -1px;
  left: -1px;
  pointer-events: none;

}

.btn-hide-validate {
  font-family: Material-Design-Iconic-Font;
  font-size: 18px;
  color: #fa4251;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  top: 0px;
  right: -28px;
}

.rs1-alert-validate.alert-validate::before {
  background-color: #fff;
}

.rs1 {
 /* width: calc((100% - 40px)/2);*/
  /*width: calc(100% - 40px);*/
  width: 100%;
}

.rs1.next {
  font-family: Raleway-SemiBold;
  color: #555;
  color: #8c8888;
  text-transform: uppercase;
}

.true-validate::after {
  content: "\f26b";
  font-family: Material-Design-Iconic-Font;
  font-size: 18px;
  color: #00ad5f;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  top: 0px;
  right: -28px;
}

/*---------------------------------------------*/
@media (max-width: 576px) {
  .btn-hide-validate {
    right: 10px;
  }
  .true-validate::after {
    right: 10px;
  }
}

.github-corner:hover .octo-arm{
  animation:octocat-wave 560ms ease-in-out
}
@keyframes octocat-wave{
  0%,100%{transform:rotate(0)}20%,60%{transform:rotate(-25deg)}40%,80%{transform:rotate(10deg)}
}
@media (max-width:500px){
  .github-corner:hover .octo-arm{
    animation:none
  }
  .github-corner .octo-arm{
    animation:octocat-wave 560ms ease-in-out
  }
}


.steg {
  font-family: Raleway-Black;
  font-size: 35px;
  font-weight: 400;
  color: #fff;
  text-shadow: 1px 1px 3px black;
}

.copy {
  position: absolute;
  width: auto;
  left: 75%;
  margin-top: 30px;
  padding: .55rem .2rem;
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  color: #fff;
  border-radius: 50px;
  background-color: #000;
  box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
  transition: all .2s ease-in-out;
  z-index: 5;
}

#clip {
  color: #fff;
  fill: #fff;
}

.d-input100{
  font-size: 15px;
  color: grey;
  line-height: 1.5;
  padding-bottom: 11px;
  font-style: italic;
  font-family: Raleway-SemiBold;
  width: 100%;
}
.cus{
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
} 

.trans{
  max-height: 500px;
  transition: max-height 1s ease-in;
}

#adv{
  cursor: pointer;
  color: black;
  padding-left: 5px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-family: Raleway-Black;
}

.wraptext {
  word-break : break-word!important;
}

.fade-in-fwd {
	-webkit-animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.6s both;
	        animation: fade-in-fwd 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.6s both;
}



/* ----------------------------------------------
 * Generated by Animista on 2022-8-26 17:45:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
              transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  